import React, { useState } from 'react';
import maplibregl, { LngLatBoundsLike, Marker } from 'maplibre-gl';
import { updateMap } from '@/utils/map';
import { useMap } from '@/hooks/useMap';
import UserLocationDisabledIcon from '@/components/ui/icons/UserLocationDisabledIcon';
import UserLocationIconSelected from '../icons/UserLoccationIconSelected';
import UserLocationIcon from '../icons/UserLocationIcon';

const ONE_DAY = 86400000;

declare const navigator: any;

interface Props {
  onSelected: (coords: {
    lat: number;
    lon: number;
    zoom: number;
    bbox?: LngLatBoundsLike;
  }) => void;
  isCenterUserLocation: boolean;
  setIsCenterUserLocation: (isCenterUserLocation: boolean) => void;
  setUserLat: (userLat: number) => void;
  setUserLon: (userLon: number) => void;
  userLat?: number;
  userLon?: number;
}

let marker: Marker | null = null;

const UserLocation: React.FC<Props> = ({
  isCenterUserLocation,
  setIsCenterUserLocation,
  setUserLat,
  setUserLon,
  userLat,
  userLon,
}) => {
  const { map } = useMap();
  const [isLocationDisabled, setIsLocationDisabled] = useState(false);

  return (
    <div
      onClick={() => {
        let lat: number;
        let lon: number;
        const el: HTMLDivElement = document.createElement('div');
        el.className =
          'w-4 bg-azure-600 h-4 rounded-full border-2 border-neutral-0 [&_svg]:hidden animate-pulseBeat';

        if ('geolocation' in navigator) {
          navigator.geolocation.getCurrentPosition(
            (position: { coords: { latitude: number; longitude: number } }) => {
              setIsLocationDisabled(false);
              setIsCenterUserLocation(true);
              if (!userLat && !userLon) {
                setUserLat(position.coords.latitude);
                setUserLon(position.coords.longitude);
                lat = position.coords.latitude;
                lon = position.coords.longitude;
              } else if (userLat && userLon) {
                lat = userLat;
                lon = userLon;
              }

              if (map) {
                updateMap(map, lat, lon, 12, [0, 0], 2);
                if (!marker) {
                  marker = new maplibregl.Marker({ element: el })
                    .setLngLat([lon, lat])
                    .addTo(map);
                }
              }
            },
            (error: { code: any; PERMISSION_DENIED: any; message: string }) => {
              console.log(error);
              if (error.code === error.PERMISSION_DENIED) {
                // location is blocked
                setIsLocationDisabled(true);
              } else {
                console.log('An error occurred');
              }
            },
            { enableHighAccuracy: false, timeout: 10000, maximumAge: ONE_DAY },
          );
        } else {
          // geolocation is not supported
          console.log('Geolocation is not supported');
        }
      }}>
      <div className="size-[32px] rounded border border-neutral-700 bg-neutral-900 p-1.5 text-neutral-0 shadow-lg transition-all hover:bg-neutral-800 group-data-[type=light]/mainMap:border-neutral-400 group-data-[type=light]/mainMap:bg-neutral-0 group-data-[type=light]/mainMap:text-neutral-900 group-data-[type=light]/mainMap:hover:bg-neutral-200 md:size-[44px] md:p-2">
        {isLocationDisabled ? (
          <UserLocationDisabledIcon className="fill-current" />
        ) : isCenterUserLocation ? (
          <UserLocationIconSelected className="fill-current" />
        ) : (
          <UserLocationIcon className="fill-current" />
        )}
      </div>
    </div>
  );
};

export default UserLocation;
