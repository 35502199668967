import { sanitizeRegion } from '@/utils/explorer';
import { ICoordsContext } from '../StrategyManager';

const RegionStrategy = ({
  regionName = '',
  regions = [],
  runNext,
  setCoords,
}: ICoordsContext) => {
  const region = regions.find(
    region => sanitizeRegion(region.name) === sanitizeRegion(regionName),
  );

  if (!region) {
    runNext();
    return;
  }
  const { lat, lon } = region?.focalPoint || {};
  if (lat && lon) {
    setCoords({ lat, lon, zoom: 6, isLocationReady: true });
  } else runNext();
};

export default RegionStrategy;
