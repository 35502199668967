import { ICoordsContext } from '../StrategyManager';

const QueryStrategy = ({ setCoords, router, runNext }: ICoordsContext) => {
  if (router.query.center) {
    const [lat, lon, zoom] = (router.query.center as string)
      .toString()
      .split(',')
      .map(num => Number(Number(num).toFixed(6)));

    if (lat && lon) {
      setCoords({ lat, lon, zoom: zoom || 11, isLocationReady: true });
      return;
    }
  }
  runNext();
};

export default QueryStrategy;
