import { SVGProps } from 'react';

export default function MapboxLogoIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width={72} height={16} viewBox="0 0 72 16" {...props}>
      <path d="M52.8555 4.42739C51.9755 4.42739 51.1311 4.79183 50.5178 5.43183V2.04516C50.5178 1.9385 50.4289 1.84961 50.3222 1.84961H49.1311C49.0244 1.84961 48.9355 1.9385 48.9355 2.04516V11.2007C48.9355 11.3074 49.0244 11.3963 49.1311 11.3963H50.3222C50.4289 11.3963 50.5178 11.3074 50.5178 11.2007V10.5696C51.1311 11.2096 51.9667 11.5741 52.8555 11.5741C54.7133 11.5741 56.2155 9.97405 56.2155 8.00072C56.2155 6.02739 54.7133 4.42739 52.8555 4.42739ZM52.58 10.1429C51.4511 10.1429 50.5355 9.20072 50.5267 8.02739V7.97405C50.5444 6.80072 51.4511 5.8585 52.58 5.8585C53.7178 5.8585 54.6333 6.8185 54.6333 8.00072C54.6333 9.18294 53.7089 10.1429 52.58 10.1429Z" />
      <path d="M60.5965 4.42743C58.5876 4.42743 56.9609 6.02743 56.9609 8.00076C56.9609 9.9741 58.5876 11.5741 60.5965 11.5741C62.6054 11.5741 64.232 9.9741 64.232 8.00076C64.232 6.02743 62.6054 4.42743 60.5965 4.42743ZM60.5876 10.143C59.4498 10.143 58.5343 9.18298 58.5343 8.00076C58.5343 6.81854 59.4587 5.85854 60.5876 5.85854C61.7165 5.85854 62.6409 6.81854 62.6409 8.00076C62.6409 9.18298 61.7165 10.143 60.5876 10.143Z" />
      <path d="M38.3657 4.60521H37.1746C37.0679 4.60521 36.979 4.6941 36.979 4.80076V5.43187C36.3657 4.79187 35.5301 4.42743 34.6413 4.42743C32.7835 4.42743 31.2812 6.02743 31.2812 8.00076C31.2812 9.9741 32.7835 11.5741 34.6413 11.5741C35.5213 11.5741 36.3657 11.2097 36.979 10.5697V11.2008C36.979 11.3074 37.0679 11.3963 37.1746 11.3963H38.3657C38.4724 11.3963 38.5613 11.3074 38.5613 11.2008V4.80076C38.5613 4.6941 38.4724 4.60521 38.3657 4.60521ZM34.9168 10.143C33.779 10.143 32.8635 9.18298 32.8635 8.00076C32.8635 6.81854 33.7879 5.85854 34.9168 5.85854C36.0457 5.85854 36.9613 6.80076 36.9701 7.9741V8.02743C36.9613 9.20076 36.0457 10.143 34.9168 10.143Z" />
      <path d="M44.3126 4.42743C43.4326 4.42743 42.5881 4.79187 41.9748 5.43187V4.80076C41.9748 4.6941 41.8859 4.60521 41.7792 4.60521H40.5881C40.4815 4.60521 40.3926 4.6941 40.3926 4.80076V13.9563C40.3926 14.063 40.4815 14.1519 40.5881 14.1519H41.7792C41.8859 14.1519 41.9748 14.063 41.9748 13.9563V10.5697C42.5881 11.2097 43.4237 11.5741 44.3126 11.5741C46.1704 11.5741 47.6726 9.9741 47.6726 8.00076C47.6726 6.02743 46.1704 4.42743 44.3126 4.42743ZM44.037 10.143C42.9081 10.143 41.9926 9.20076 41.9837 8.02743V7.9741C42.0015 6.80076 42.9081 5.85854 44.037 5.85854C45.1748 5.85854 46.0904 6.81854 46.0904 8.00076C46.0904 9.18298 45.1748 10.143 44.037 10.143Z" />
      <path d="M27.7168 4.42754C26.8279 4.43643 26.0191 4.95199 25.6368 5.76088C25.2013 4.93421 24.3302 4.41865 23.3968 4.42754C22.6679 4.42754 21.9835 4.7831 21.5568 5.36976V4.80088C21.5568 4.69421 21.4679 4.60532 21.3613 4.60532H20.1702C20.0635 4.60532 19.9746 4.69421 19.9746 4.80088V11.2009C19.9746 11.3075 20.0635 11.3964 20.1702 11.3964H21.3613C21.4679 11.3964 21.5568 11.3075 21.5568 11.2009V7.36977C21.6013 6.51643 22.1968 5.83199 22.9257 5.83199C23.6813 5.83199 24.3124 6.4631 24.3124 7.28976V11.2009C24.3124 11.3075 24.4013 11.3964 24.5079 11.3964H25.7079C25.8146 11.3964 25.9035 11.3075 25.9035 11.2009L25.8946 7.21865C26.0013 6.43643 26.5613 5.83199 27.2457 5.83199C28.0013 5.83199 28.6324 6.4631 28.6324 7.28976V11.2009C28.6324 11.3075 28.7213 11.3964 28.8279 11.3964H30.0279C30.1346 11.3964 30.2235 11.3075 30.2235 11.2009L30.2146 6.80088C30.2146 5.48532 29.0946 4.42754 27.7168 4.42754Z" />
      <path d="M70.6421 11.1208L68.5799 7.98304L70.6243 4.87193C70.6777 4.79193 70.651 4.67637 70.571 4.62304C70.5443 4.60526 70.5088 4.59637 70.4732 4.59637H69.0954C68.9888 4.59637 68.891 4.64971 68.8377 4.7386L67.6377 6.75637L66.4377 4.74749C66.3843 4.6586 66.2866 4.60526 66.1799 4.60526H64.8021C64.7043 4.60526 64.6243 4.68526 64.6243 4.78304C64.6243 4.8186 64.6332 4.85415 64.651 4.88082L66.6954 7.99193L64.6332 11.1297C64.5799 11.2097 64.6066 11.3253 64.6866 11.3786C64.7132 11.3964 64.7488 11.4053 64.7843 11.4053H66.1621C66.2688 11.4053 66.3666 11.3519 66.4199 11.263L67.6466 9.2186L68.8732 11.263C68.9265 11.3519 69.0243 11.4053 69.131 11.4053H70.491C70.5888 11.4053 70.6688 11.3253 70.6688 11.2275C70.6688 11.1919 70.6599 11.1564 70.6421 11.1208Z" />
      <path d="M8.34754 0.0985718C3.98309 0.0985718 0.445312 3.63635 0.445312 8.00079C0.445312 12.3652 3.98309 15.903 8.34754 15.903C12.712 15.903 16.2498 12.3652 16.2498 8.00079C16.2498 3.63635 12.712 0.0985718 8.34754 0.0985718ZM12.0986 9.93857C9.39643 12.6408 4.56976 11.7786 4.56976 11.7786C4.56976 11.7786 3.69865 6.96079 6.40976 4.24968C7.91198 2.74746 10.4009 2.80968 11.9742 4.37413C13.5475 5.93857 13.6009 8.43635 12.0986 9.93857Z" />
      <path d="M9.25507 4.72955L8.48174 6.32066L6.89062 7.094L8.48174 7.86733L9.25507 9.45844L10.0284 7.86733L11.6195 7.094L10.0284 6.32066L9.25507 4.72955Z" />
    </svg>
  );
}
