import { getCountryDataByCountryCode } from '@/api/map';
import { ICoordsContext } from '../StrategyManager';
import { MOBILE_SCREEN_WIDTH } from './DefaultLocationStrategy';

const UserLocationStrategy = ({
  setCoords,
  runNext,
  country,
  screenWidth,
}: ICoordsContext) => {
  if (country) {
    const { coordinates, zoom } = getCountryDataByCountryCode(country) || {};
    let finalZoom = zoom;
    const [lon, lat] = coordinates || [];
    if (screenWidth && finalZoom && screenWidth < MOBILE_SCREEN_WIDTH) {
      // lets zoom out a bit for mobile
      finalZoom = Math.max(finalZoom - 1.5, 1);
    }
    if (lat && lon && finalZoom) {
      setCoords({
        lat,
        lon,
        zoom: finalZoom,
        isLocationReady: true,
      });
      return;
    }
  }
  runNext();
};

export default UserLocationStrategy;
