import { Dispatch, SetStateAction, useEffect } from 'react';
import { Map } from 'maplibre-gl';
import 'maplibre-gl/dist/maplibre-gl.css';
import { useRouter } from 'next/router';
import { updateQueryParam } from '@/utils/url';
import { ILocation, IRegion } from '@/types/explorer';
import * as storage from '@/utils/storage';
import StrategyManager from './StrategyManager';

interface Props {
  map?: Map;
  regionName: string;
  regions: IRegion[];
  screenWidth?: number;
  setCoords: Dispatch<SetStateAction<Partial<ILocation>>>;
  country?: string;
  rememberLocation?: boolean;
  onMapMoved?: ([lon, lat]: [number | undefined, number | undefined]) => void;
}

let inProgress = false;
const useCoords = ({
  map,
  rememberLocation,
  onMapMoved,
  ...context
}: Props) => {
  const router = useRouter();
  if (!inProgress) {
    StrategyManager({ ...context, router }).run();
    inProgress = true;
  }

  useEffect(() => {
    return () => {
      inProgress = false;
    };
  }, []);

  useEffect(() => {
    if (!map) return;
    map.on('moveend', (e: any) => {
      let hasMapMoved = true;
      // e[0] is related to ResizeObserver and it's undefined when the map is moved by the user
      // This condition fixes a bug in which the map move event is triggered even when changing pages
      if (!e || (e && e[0])) {
        hasMapMoved = false;
      }
      if (hasMapMoved) {
        const { lng, lat } = map.getCenter();
        const zoom = map.getZoom();
        updateQueryParam(
          'center',
          [lat.toFixed(6), lng.toFixed(6), zoom].join(','),
          undefined,
          false,
        );
        onMapMoved && onMapMoved([lng, lat]);
        if (rememberLocation) {
          storage.set(
            storage.STORAGE_KEYS.LATEST_LOCATION,
            {
              lat,
              lon: lng,
              zoom,
            },
            10,
          );
        }
      }
    });
  }, [map, onMapMoved, rememberLocation]);
};

export default useCoords;
