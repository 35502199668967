import { SVGProps } from 'react';

export default function UserLocationIconSelected(
  props: SVGProps<SVGSVGElement>,
) {
  return (
    <svg viewBox="0 0 27 26" {...props}>
      <path d="M1.7379 10.8477C-0.112436 11.7032 0.410601 14.1843 2.44255 14.1985L12.1901 14.2298C12.3494 14.2298 12.3894 14.284 12.3894 14.429L12.4207 24.1196C12.4349 26.2286 14.953 26.6063 15.8566 24.6684L25.7327 3.39079C26.6623 1.41798 25.1016 0.0055089 23.1349 0.920701L1.7379 10.8477Z" />
    </svg>
  );
}
