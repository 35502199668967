import { ICoordsContext } from '../StrategyManager';

// SF coords
const DEFAULT_VIEW_STATE = [29.852932, -37.047117, 1.2];
const MOBILE_VIEW_STATE = [38.098957, -82.962853, 3.7];

export const MOBILE_SCREEN_WIDTH = 767;

const DefaultLocationStrategy = ({
  setCoords,
  screenWidth = 0,
}: ICoordsContext) => {
  const [lat, lon, zoom] = DEFAULT_VIEW_STATE;
  if (screenWidth < MOBILE_SCREEN_WIDTH) {
    const [lat, lon, zoom] = MOBILE_VIEW_STATE;
    setCoords({ lat, lon, zoom, isLocationReady: true });
  } else {
    setCoords({ lat, lon, zoom, isLocationReady: true });
  }
};

export default DefaultLocationStrategy;
