import { Dispatch, SetStateAction } from 'react';
import { NextRouter } from 'next/router';
import { ILocation, IRegion } from '@/types/explorer';
import DefaultLocationStrategy from './Strategies/DefaultLocationStrategy';
import QueryStrategy from './Strategies/QueryStrategy';
import RegionStrategy from './Strategies/RegionStrategy';
import UserLocationStrategy from './Strategies/UserLocationStrategy';
import LatestLocationStrategy from './Strategies/LatestLocationStrategy';

export interface ICoordsContext {
  regionName: string;
  regions: IRegion[];
  router: NextRouter;
  setCoords: Dispatch<SetStateAction<Partial<ILocation>>>;
  runNext: () => void;
  screenWidth?: number;
  country?: string;
  mapId?: string;
}

const StrategyManager = (context: Omit<ICoordsContext, 'runNext'>) => {
  const strategies = [
    QueryStrategy,
    RegionStrategy,
    LatestLocationStrategy,
    UserLocationStrategy,
    DefaultLocationStrategy,
  ];
  let currentStrategy = 0;

  const _runNext = () => {
    currentStrategy++;
    _runStrategy();
  };

  const _runStrategy = () => {
    if (currentStrategy >= strategies.length) return;
    strategies[currentStrategy]({ ...context, runNext: _runNext });
  };

  return { run: _runStrategy };
};

export default StrategyManager;
